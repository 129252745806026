<template>
  <div class="set_section_container">
    <div class="header">
      <div class="close_button">
        <button class="close_button" @click="cancel"><img src="@/assets/images/icons/icon_close_gray.svg"></button>
      </div>
    </div>
    <div v-if="!loading" class="set_section_inner">
      <table class="section_table">
        <thead>
          <tr>
            <th>Company Role</th>
            <th>Section</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Exporter</td>
            <td>
              <tw-mcu-picker :section="exporter" :options="exporterSections" placeholder="Company/Section" @input="setExporter" />
            </td>
          </tr>
          <tr>
            <td>Importer</td>
            <td>
              <tw-mcu-picker :section="importer" :options="importerSections" placeholder="Company/Section" @input="setImporter" />
            </td>
          </tr>
          <tr>
            <td>Forwarder</td>
            <td>
              <tw-mcu-picker :section="forwarder" :options="forwarderSections" placeholder="Company/Section" @input="setForwarder" />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="check_area">
        <el-checkbox class="overwrite" v-model="overWrite">Overwrite</el-checkbox>
      </div>
      <div class="button_area">
        <tw-button type="default" size="medium" @click="cancel">Cancel</tw-button>
        <tw-button type="primary" size="medium" :disabled="!(exporter.length || importer.length || forwarder.length)" @click="register">OK</tw-button>
      </div>
    </div>
    <div v-else class="loading" v-loading="loading"></div>
  </div>
</template>

<script>
import _ from 'lodash';
import { COMPANY_ROLE_CD, FROM_TO_TYPE, DELETE_TYPE } from 'lib-tw-common';
import { sectionForCascader, sectionForPulldown } from '@/utils/searchUtil';
// import { $api } from '@/store/ApiClient';
import TwButton from '@/components/atoms/TwButton';
import TwMcuPicker from '@/components/molecules/TwMcuPicker';

export default {
  name: 'TwSetSection',
  components: {
    TwButton,
    TwMcuPicker
  },
  props: {
    tradingInfo: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      loading: false,
      sections: [],
      sectionDict: [],
      value: '',
      exporter: [],
      importer: [],
      forwarder: [],
      exporterSections: [],
      importerSections: [],
      forwarderSections: [],
      overWrite: false,
      COMPANY_ROLE_CD: COMPANY_ROLE_CD
    };
  },
  computed: {},
  async created() {
    this.loading = true;
    Promise.all([this.fetch(COMPANY_ROLE_CD.EXPORTER), this.fetch(COMPANY_ROLE_CD.IMPORTER), this.fetch(COMPANY_ROLE_CD.EXPORT_FREIGHT_FORWARDER)])
      .then(() => {
        this.sectionDict = _.uniqBy(this.sectionDict, 'value');
        this.loading = false;
      })
      .catch(() => this.$emit('close'));
  },
  methods: {
    async fetch(role) {
      const query = {
        companyRoleCd: role,
        fromToFlg: FROM_TO_TYPE.FROM,
        deleteType: DELETE_TYPE.NOT_DELETED,
        // FY2022V2PH6-14 フロー編集時、プロセス追加する際の選択可能な会社がOwner縛りになっている
        // ownerCompanyId: _.get(this.tradingInfo, 'tradingPatternInfo.ownerCompanyId')
      };

      // 取引先セクション取得BFF
      await this.$store
        .dispatch('GET_SECTIONS', query)
        .then(res => {
          this.sectionDict = _.concat(this.sectionDict, sectionForPulldown(res.customerSectionList));
          const result = sectionForCascader(res.customerSectionList);
          switch (role) {
            case COMPANY_ROLE_CD.EXPORTER:
              this.exporterSections = result;
              break;
            case COMPANY_ROLE_CD.IMPORTER:
              this.importerSections = result;
              break;
            case COMPANY_ROLE_CD.EXPORT_FREIGHT_FORWARDER:
              this.forwarderSections = result;
              break;
          }
          return Promise.resolve();
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          return Promise.reject();
        });
    },
    setExporter(value) {
      this.exporter = value;
    },
    setImporter(value) {
      this.importer = value;
    },
    setForwarder(value) {
      this.forwarder = value;
    },
    cancel() {
      if (this.exporter.length || this.importer.length || this.forwarder.length) {
        this.$store
          .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete this data?')
          .then(() => {
            this.$emit('close');
          })
          .catch(() => {});
      } else {
        this.$emit('close');
      }
    },
    register() {
      const targetExporter = this.getSection(this.exporter);
      const targetImporter = this.getSection(this.importer);
      const targetForwarder = this.getSection(this.forwarder);
      this.$emit('register', targetExporter, targetImporter, targetForwarder, this.overWrite);
    },
    getSection(target) {
      if (target.length && target[1]) {
        return _.find(this.sectionDict, { value: target[1] });
      } else return null;
    }
  }
};
</script>

<style lang="scss" scoped>
.loading {
  width: 100%;
  min-height: 300px;
  height: 100%;
  position: relative;
}
.close_button {
  position: absolute;
  top: 8px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: none;
}
.set_section_inner {
  position: relative;
  z-index: 2;
  table.section_table {
    width: 100%;
    border-collapse: collapse;
    padding: 0;
    @include card_shadow;
    border-radius: 8px;
  }
  th {
    width: 240px;
    font-size: 12px;
    color: $color_gray_800;
    font-weight: 400;
    vertical-align: middle;
    text-align: center;
    padding: 8px 16px;
  }
  tr {
    border-bottom: 1px solid $color_gray_300;
  }
  td {
    font-size: 14px;
    font-weight: bold;
    vertical-align: middle;
    padding: 8px 16px;
  }
}
.check_area {
  padding: 16px 0;
}
.button_area {
  display: flex;
  justify-content: right;
}
</style>
