<template>
  <div v-if="!loading && tradingPatternInfo" class="view_pattern_inner">
    <header>
      <h1>{{tradingPatternName || tradingPatternInfo.tradingPatternName || $t('Label.New Trading')}}</h1>
    </header>
    <section>
      <table>
        <tr>
          <th>{{$t('Label.Owner')}}</th>
          <td>{{sectionShortName}}</td>
        </tr>
        <tr>
          <th>{{$t('Label.Trading Type')}}</th>
          <td>{{tradeType}}</td>
        </tr>
        <template v-if="!isOffshore">
          <tr>
            <th>{{$t('Label.Payment Terms')}}</th>
            <td>{{paymentTerms}}</td>
          </tr>
          <tr>
            <th>{{$t('Label.Trade Terms')}}</th>
            <td>{{tradeTerms}}</td>
          </tr>
          <tr>
            <th>{{$t('Label.Mode of Transport')}}</th>
            <td>{{modeOfTransport}}</td>
          </tr>
        </template>
      </table>
    </section>
    <template v-for="(flow, index) in flowList">
      <div v-if="flow.entityStatus.length" :key="index">
        <section v-if="isOffshore">
          <div class="flow_type" :class="{ _im: flow.tradeType === 'Import' }">{{flow.tradeType}}</div>
          <table>
            <tr>
              <th>{{$t('Label.Payment Terms')}}</th>
              <td>{{flow.paymentTerms}}</td>
            </tr>
            <tr>
              <th>{{$t('Label.Trade Terms')}}</th>
              <td>{{flow.tradeTerms}}</td>
            </tr>
            <tr>
              <th>{{$t('Label.Mode of Transport')}}</th>
              <td>{{flow.modeOfTransport}}</td>
            </tr>
          </table>
        </section>
        <section class="process_table_section">
          <tw-process-table :schemas="processSchemas" :items="flow.entityStatus" :flow="flow" />
        </section>
      </div>
    </template>
  </div>
  <div v-else class="loading" v-loading="loading"></div>
</template>

<script>
import _ from 'lodash';
import { FLOW_TYPE_VARIABLES, DELIVERY_TERMS_VARIABLES, MODE_OF_TRANSPORT_VARIABLES, PAYMENT_TERMS_VARIABLES, TRADE_TYPE_VARIABLES, TRADE_TYPE, FLOW_TYPE, OFFSHORE_TYPE } from 'lib-tw-common';
import { $api } from '@/store/ApiClient';
import { orderEntities } from '@/utils/searchUtil.js';
import TwProcessTable from '@/components/molecules/TwProcessTable';
import processSchemas from '@/dictionaries/manageTradingViewPatternListProcessSchema.json';

// スタブ
// import tradingPatterns from '@/../public/tradingPatterns.json';

export default {
  name: 'TwViewPattern',
  components: {
    TwProcessTable
  },
  props: {
    tradingInfo: {
      type: Object,
      default: () => {}
    },
    tradingPatternName: {
      type: String,
      default: ''
    },
    ownerSectionName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tradingPatternInfo: null,
      flowList: [],
      isOffshore: false, // 元となるベースパターンが三国間取引か
      loading: false,
      processSchemas: processSchemas,
      DELIVERY_TERMS_VARIABLES: DELIVERY_TERMS_VARIABLES,
      MODE_OF_TRANSPORT_VARIABLES: MODE_OF_TRANSPORT_VARIABLES,
      PAYMENT_TERMS_VARIABLES: PAYMENT_TERMS_VARIABLES,
      TRADE_TYPE_VARIABLES: TRADE_TYPE_VARIABLES
    };
  },
  computed: {
    isNewTrading() {
      // New Trading判定
      return !_.get(this, 'tradingInfo.tradingCreateDate');
    },
    sectionShortName() {
      return this.ownerSectionName || _.get(this, 'tradingPatternInfo.sectionShortName');
    },
    tradeType() {
      const tradeType = this.isNewTrading ? _.get(this, 'tradingPatternInfo.tradeTypeCd') : _.get(this, 'tradingPatternInfo.tradeType');
      return this.getValue(tradeType, TRADE_TYPE_VARIABLES);
    },
    paymentTerms() {
      const paymentTerms = this.isNewTrading ? _.get(this, 'tradingPatternInfo.paymentTermsCd') : _.get(this, 'tradingPatternInfo.paymentTerms');
      return this.getValue(paymentTerms, PAYMENT_TERMS_VARIABLES);
    },
    tradeTerms() {
      const tradeTerms = this.isNewTrading ? _.get(this, 'tradingPatternInfo.tradeTermsCd') : _.get(this, 'tradingPatternInfo.tradeTerms');
      return this.getValue(tradeTerms, DELIVERY_TERMS_VARIABLES);
    },
    modeOfTransport() {
      const modeOfTransport = this.isNewTrading ? _.get(this, 'tradingPatternInfo.modeOfTransportCd') : _.get(this, 'tradingPatternInfo.modeOfTransport');
      return this.getValue(modeOfTransport, MODE_OF_TRANSPORT_VARIABLES);
    }
  },
  created() {
    if (this.isNewTrading) {
      // New Tradingの場合はtmpProcessListからパラメータ生成
      this.tradingPatternInfo = _.get(this, 'tradingInfo.tradingPatternInfo', {});
      const tradeTypeCd = _.get(this, 'tradingInfo.tradingPatternInfo.tradeTypeCd');
      const tmpTradingFlowList = _.get(this, 'tradingInfo.tradingPatternInfo.tmpTradingFlowList', []);
      // 三国間取引かどうかの判定
      this.isOffshore = tradeTypeCd === TRADE_TYPE.OFF_SHORE && tmpTradingFlowList.length > 1;
      // 表示用フローを生成
      this.flowList = _.map(tmpTradingFlowList, flow => {
        return this.createTmpEntityStatus(flow, this.tradingPatternInfo);
      });
    } else {
      // New Trading以外の場合はBFFからFetch
      this.fetch();
    }
  },
  methods: {
    async fetch() {
      this.loading = true;

      // ----スタブ-----
      // await new Promise(resolve => setTimeout(resolve, 2000)); // 確認用にN秒待つ
      // this.tradingPatternInfo = tradingPatterns.tradingPatterns[0];
      // this.entityStatus = this.createEntityStatus(_.get(tradingPatterns, 'tradingPatterns[0].processRelation'));
      // // this.createEntityStatus(_.get(tradingPatterns, 'tradingPatterns[0].processRelation'));
      // console.log(this.tradingPatternInfo);
      // console.log(this.entityStatus);
      // this.loading = false;
      // return Promise.resolve();

      const params = {
        // bff_trading_pattern_15 取引パターン取得（トラン用）BFF
        lslConfig: {
          serviceCode: 'tw-user-entity-bff-api',
          apiCode: 'get_/v1/{companyId}/trading-patterns/{tradingPatternId}/transaction',
          query: {
            tradingCreateDate: this.tradingInfo.tradingCreateDate
          },
          path: {
            companyId: this.$store.state.companyId,
            tradingPatternId: this.tradingInfo.tradingPatternInfo.tradingPatternId
          }
        }
      };

      $api
        .request(params)
        .then(res => {
          this.tradingPatternInfo = _.get(res, 'tradingPatterns[0]', {});
          // 三国間取引かどうかの判定
          this.isOffshore = this.tradingPatternInfo.offshoreType !== OFFSHORE_TYPE.NOT_OFFSHORE;
          // console.log(this.tradingPatternInfo, this.isOffshore)
          // 表示用フローを生成
          // TODO: 三国間の場合、レスポンスの配列がEx→Imの順序になっていないため生成後にoffshoreTypeでソート
          // offshoreTypeは、Im: 1, EX: 2なので、ソート後に降順に変更
          this.flowList = _.sortBy(_.map(res.tradingPatterns, flow => {
            return this.createEntityStatus(flow, this.tradingPatternInfo);
          }), 'offshoreType').reverse();
          // console.log(this.flowList)
          this.loading = false;
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          this.$emit('close');
        });
    },
    // 取引パターン取得（トラン用）BFFレスポンス加工 processRelationをentityStatus形式へ変換
    createEntityStatus(flow) {
      const entity = _.groupBy(flow.processRelation, 'entityType');
      const entityStatus = _.map(entity, item => {
        return {
          entityTypeCd: item[0].entityType,
          processStatus: item
        };
      });
      let result = {
        entityStatus: orderEntities(entityStatus),
        offshoreType: flow.offshoreType
      }
      if (this.isOffshore) {
        // 三国間取引の場合は、決済条件/貿易条件/輸送形態を追加
        result.tradeType = flow.offshoreType === OFFSHORE_TYPE.IMPORTER_SIDE ? 'Import' : 'Export';
        result.paymentTerms = this.getValue(flow.paymentTerms, PAYMENT_TERMS_VARIABLES);
        result.tradeTerms = this.getValue(flow.tradeTerms, DELIVERY_TERMS_VARIABLES);
        result.modeOfTransport = this.getValue(flow.modeOfTransport, MODE_OF_TRANSPORT_VARIABLES);
      }
      return result;
    },
    // New Tradingの場合はtmpProcessListをentityStatus形式へ変換
    createTmpEntityStatus(flow, tradingPatternInfo) {
      const processes = _.map(flow.tmpProcessList, item => {
        return _.mapKeys(item, (value, key) => {
          if (_.isString(key) && key.startsWith('tmp')) {
            // tmp文字列を除外し頭小文字のkey変換
            return (
              key
                .slice(3)
                .charAt(0)
                .toLowerCase() + key.slice(4)
            );
          }
          return key;
        });
      });
      const entity = _.groupBy(processes, 'entityType');
      const entityStatus = _.map(entity, item => {
        return {
          entityTypeCd: item[0].entityType,
          processStatus: item
        };
      });
      let result = {
        entityStatus: orderEntities(entityStatus)
      };
      if (this.isOffshore) {
        // 三国間取引の場合は、決済条件/貿易条件/輸送形態を追加
        const flowType = flow.tmpFlowType;
        // 輸出 = TermsCd, 輸入 = extra~~TermsCd
        const paymentTermsCd = flowType === FLOW_TYPE.EXPORT ? tradingPatternInfo.paymentTermsCd : tradingPatternInfo.extraPaymentTermsCd;
        const tradeTermsCd = flowType === FLOW_TYPE.EXPORT ? tradingPatternInfo.tradeTermsCd : tradingPatternInfo.extraTradeTermsCd;
        const modeOfTransportCd = flowType === FLOW_TYPE.EXPORT ? tradingPatternInfo.paymentTermsCd : tradingPatternInfo.extraModeOfTransportCd;
        result.tradeType = this.getValue(flowType, FLOW_TYPE_VARIABLES);
        result.paymentTerms = this.getValue(paymentTermsCd, PAYMENT_TERMS_VARIABLES);
        result.tradeTerms = this.getValue(tradeTermsCd, DELIVERY_TERMS_VARIABLES);
        result.modeOfTransport = this.getValue(modeOfTransportCd, MODE_OF_TRANSPORT_VARIABLES);
      }
      return result;
    },
    getValue(value, variables) {
      return _.get(_.find(variables, { code: value }), 'label', '');
    }
  }
};
</script>

<style lang="scss">
.view_pattern_dialog {
  .el-dialog__body {
    padding: 8px 0 16px;
  }
  .loading {
    width: 100%;
    min-height: 640px;
    height: 100%;
  }
  .view_pattern_inner {
    header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 0 0 24px;
      border-bottom: 1px solid $color_gray_300;
      margin-bottom: 16px;
      h1 {
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        margin: 0;
      }
    }
    section {
      border-bottom: 1px solid $color_gray_300;
      padding-bottom: 4px;
      margin-bottom: 24px;
      &.process_table_section {
        @include card_shadow;
        border-bottom: none;
        border-radius: 6px;
        padding-bottom: 0;
        .tw_table.process_table {
          border-radius: 6px;
          th {
            padding: 7px 0;
            background: $color_gray_300;
          }
          thead tr:first-child:after {
            box-shadow: none;
          }
          td {
            padding: 0;
          }
        }
      }
      table {
        width: 100%;
        border-collapse: collapse;
        padding: 0;
      }
      th {
        width: 240px;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        vertical-align: middle;
        text-align: left;
        padding: 0 8px 16px 0;
      }
      td {
        font-size: 16px;
        vertical-align: middle;
        padding: 0 0 16px;
      }
      .flow_type {
        font-size: 10px;
        color: $color_white;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 58px;
        height: 16px;
        margin-bottom: 12px;
        border-radius: 15.5px;
        background: linear-gradient(80.82deg, #4EB100 -17.87%, #C8E416 101.59%);
        &._im {
          background: linear-gradient(237.29deg, #FDCA66 5.38%, #FF1203 90.56%);
        }
      }
    }
  }
}
</style>
