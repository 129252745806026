<template>
  <div v-if="show">
    <el-form :model="form" :rules="rules" :validate-on-rule-change="false" ref="form">
      <tw-header>
        <template v-slot:title>
          <el-form-item class="trading_name" label="Trading Name" prop="newTradingName">
            <el-input v-model="form.newTradingName" placeholder="Trading Name" show-word-limit maxlength="100" />
          </el-form-item>
        </template>
      </tw-header>

      <div class="tw_container" ref="container">
        <div class="flex">
          <div class="back" style="margin-bottom: 0" @click="back">
            <img src="@/assets/images/icons/chevron_left_gray800.svg">Back
          </div>
          <div class="buttons">
            <tw-button v-if="tradingId" type="delete" size="medium" @click="deleteDraft">Delete</tw-button>
            <tw-button v-else type="default" size="medium" @click="cancel">Cancel</tw-button>
            <tw-button type="secondary" size="medium" icon="plus" :disabled="!canAddNewFlow" @click="addFlow">New</tw-button>
            <tw-button type="primary" size="medium" :disabled="!needSaveButton" @click="save">Save</tw-button>
            <tw-button type="primary" size="medium" :disabled="!needRegisterButton" @click="register">Register</tw-button>
          </div>
        </div>
        <div v-if="mode && tradingInfo && tradingInfo.isOwnerFlg === IS_OWNER_FLG.ON" class="sub_buttons">
          <el-dropdown trigger="click" placement="bottom" @command="onDropDownClick">
            <tw-button class="menu_button" type="secondary" size="small" icon="menu" :disabled="!(currentFlow && currentFlow.entityStatus.length) || !tradingInfo.tradingPatternInfo">Menu</tw-button>
            <el-dropdown-menu class="nowrap">
              <el-dropdown-item command="viewPattern">View Pattern</el-dropdown-item>
              <el-dropdown-item command="setSection">Set Section</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div ref="pos" />

        <tw-table-system ref="table" :expand="true" :defaultExpandAll="true" :schemas="schemas" :processSchemas="processSchemas" :items="form.items" :pageSize="pageSize" :selection="selection" :nopager="true" :mode="mode" :height="maxHeight" :rowKey="getRowKey" :expandRowKeys="expandRowKeys" :class="['new', {validation: shouldValidation, disabled: tradingInfo && tradingInfo.editableFlg === EDITABLE_FLG.OFF}]" :flowNameRule="flowNameRule" @remove-flow="removeFlow" @current-change="handleCurrentChange" :highlight-current-row="true" />
      </div>
    </el-form>

    <!-- View Pattern -->
    <el-dialog :visible.sync="isShowViewPattern" width="1240px" :close-on-click-modal="false" :append-to-body="true" class="view_pattern_dialog">
      <TwViewPattern v-if="isShowViewPattern" :tradingInfo="tradingInfo" :tradingPatternName="tradingPatternName" :ownerSectionName="ownerSectionName" @close="toggleViewPattern" />
    </el-dialog>
    <!-- Set Section -->
    <el-dialog :title="$t('Label.Set Section')" :visible.sync="isShowSetSection" width="617px" :show-close="false" :close-on-click-modal="false" :append-to-body="true" class="set_section_dialog header_border">
      <TwSetSection v-if="isShowSetSection" :tradingInfo="tradingInfo" :currentFlow="currentFlow" @close="toggleSetSection" @register="assignSection" />
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import { SECTION_PREFIX, EDITABLE_FLG, DELETE_ABLE_FLG, COMPANY_ROLE_CD, IS_OWNER_FLG, VISIBLE_FLG, ABLE_FLG} from 'lib-tw-common';
import mixinEntityList from '@/utils/mixinEntityList.js';
import mixinValidation from '@/utils/mixinValidation.js';
import { processNames } from '@/dictionaries/map.js';
import { generateUuid, orderEntities } from '@/utils/searchUtil.js';
import { orderProcesses } from '@/utils/entity.js';
import schemas from '@/dictionaries/newTradingListSchema.json';
import processSchemas from '@/dictionaries/newTradingListProcessSchema.json';
import TwViewPattern from '@/components/organisms/TwViewPattern';
import TwSetSection from '@/components/organisms/TwSetSection';

export default {
  name: 'NewTrading',
  mixins: [mixinEntityList, mixinValidation],
  props: {
    tradingPatternId: String,
    tradingName: String,
    originTradingPatternName: String,
    ownerSectionId: String,
    ownerSectionName: String,
    history: Object,
    tradingId: String,
  },
  components: {
    TwViewPattern,
    TwSetSection,
  },
  provide() {
    return {
      addProcess: this.addProcess,
      setProcess: this.setProcess,
      removeProcess: this.removeProcess,
      getPairFlows: this.getPairFlows,
      setProcessUserMemo: this.setProcessUserMemo,
      s: this,
    };
  },
  data() {
    return {
      schemas: schemas,
      processSchemas: processSchemas,
      mode: 'new',
      tradingInfo: null,
      processList: [],
      form: {
        newTradingName: this.tradingName,
        items: null,
      },
      rules: {
        newTradingName: [
          {
            lakeelMessageSourceFlg: true,
            validationId: 'tw-isMaxLength100',
            validator: this.onEventValidation, trigger: 'blur'
          }
        ],
      },
      flowNameRule: [
        {
          lakeelMessageSourceFlg: true,
          validationId: 'tw-isMaxLength100',
          validator: this.onEventValidation, trigger: 'blur'
        }
      ],
      show: false,
      tmpTradingFlowList: [],
      deleteTradingFlowIds: [],
      shouldValidation: false, // バリデーション表示の可否
      EDITABLE_FLG,
      IS_OWNER_FLG,
      maxHeight: window.innerHeight - 200,
      currentFlow: null,
      isShowViewPattern: false,
      isShowSetSection: false,
      tmpChannelFlowNo: 0,
      expandRowKeys: [],
    };
  },
  computed: {
    drawerShow() {
      return this.$store.state.drawerShow;
    },
    needSaveButton() {
      return this.form.items && !!this.form.items.length;
    },
    needRegisterButton() {
      return this.form.items && !!this.form.items.length;
      // _.get(this.tradingInfo, 'tradingPatternInfo.approveStatus') === APPROVE_STATUS.APPROVAL;
    },
    tradingPatternName() {
      return _.get(this, 'originTradingPatternName') || _.get(this, 'tradingInfo.tradingPatternName');
    },
    canAddNewFlow() {
      return this.tradingInfo && this.tradingInfo.canAddNewFlowFlg === ABLE_FLG.TRUE;
    },
  },
  watch: {
    companyId() {
      this.fetch();
    }
  },
  created() {
    if (!this.tradingPatternId && !this.tradingId) {
      if (this.$store.getters.isShipper) {
        this.$router.replace({ name: this.$store.state.tradingListMode, params: { history: {} } });
      } else {
        this.$router.replace({ name: 'Page404' });
      }

      return;
    }
    this.show = true;
    this.$store.commit('SET_DRAWER', false);

    if (this.companyId) {
      this.fetch();
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize, false);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    back() {
      this.$store
      .dispatch('SHOW_CONFIRM', 'Are you sure to go back? (Unsaved data shall be deleted)')
      .then(() => {
        if (this.history) {
          this.$router.replace({ name: this.history.name, params: { history: this.history } });
        } else if (window.history.length > 2) {
          this.$router.go(-1);
        } else {
          this.$router.push('/');
        }
      })
      .catch(() => {});
    },
    fetch() {
      const params = {
        // bff_td_4 取引管理新規登録初期表示BFF
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/tradings/{companyId}/manages/editor',
          path: {
            companyId: this.companyId,
          },
          query: {
            tradingPatternId: this.tradingPatternId,
            tradingId: this.tradingId,
          }
        }
      };


      $api
        .request(params)
        .then(res => {
          this.tradingInfo = res.tradingInfo;
          this.tmpTradingFlowList = res.tradingInfo.tradingPatternInfo.tmpTradingFlowList;
          this.processList = res.processList;
          if (this.tradingInfo.tradingName) {
            this.form.newTradingName = this.tradingInfo.tradingName;
          }
          if (res.tradingInfo.tradingFlowList && res.tradingInfo.tradingFlowList.length) {
            this.form.items = this.flatten(res.tradingInfo.tradingFlowList, 'linkageInfo');
          } else {
            this.form.items = this.createNewFlows();
          }
          this.setExpandRowKeys();
        })
        .catch(err => {
          this.form.items = [];
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    // 指定したオブジェクトの中身を一階層上に展開
    flatten(ary, target) {
      return _.map(ary, o => {
        return { ..._.omit(o, [target]), ...o.linkageInfo };
      });
    },
    showItem() {

    },
    rowClick() {

    },
    /**
     * 新規プロセスを返却します
     * @param { Object }  p プロセスモデル
     * @param { Boolean } plane 中身を空にするか
     */
    createNewProcess(p, plane) {
      return {
        processType: p.tmpProcessType,
        processId: p.tmpProcessId,
        processName: p.tmpProcessName,
        processUserMemo: p.tmpProcessUserMemo,
        fromCompanyRoleCd: parseInt(p.tmpFromCompanyRoleCd),
        fromCustomerId: plane ? '' : p.tmpFromCustomerId,
        fromCompanyId: plane ? '' : p.tmpFromCompanyId,
        fromCompanyName: plane ? '' : p.tmpFromCompanyName,
        fromCompanyShortName: plane ? '' : p.tmpFromCompanyShortName,
        fromSectionId: plane ? '' : p.tmpFromSectionId,
        fromSectionName: plane ? '' : p.tmpFromSectionName,
        fromSectionShortName: plane ? '' : p.tmpFromSectionShortName,
        toCompanyRoleCd: parseInt(p.tmpToCompanyRoleCd),
        toCustomerId: plane ? '' : p.tmpToCustomerId,
        toCompanyId: plane ? '' : p.tmpToCompanyId,
        toCompanyName: plane ? '' : p.tmpToCompanyName,
        toCompanyShortName: plane ? '' : p.tmpToCompanyShortName,
        toSectionId: plane ? '' : p.tmpToSectionId,
        toSectionName: plane ? '' : p.tmpToSectionName,
        toSectionShortName: plane ? '' : p.tmpToSectionShortName,
        uuid: generateUuid(),
        editableFlg: EDITABLE_FLG.ON,
        deletableFlg: DELETE_ABLE_FLG.OK,
      };
    },
    // 新しいフローを返却します
    createNewFlow(tmpFlow) {
      const g = _.groupBy(tmpFlow.tmpProcessList, 'tmpEntityType');
      const entityStatus = _.map(g, ary => {
        return {
          entityTypeCd: ary[0].tmpEntityType,
          processStatus: _.map(ary, p => {
            return this.createNewProcess(p);
          })
        };
      });
      return {
        tradingManageName: '',
        flowUuid: generateUuid(),
        entityStatus: orderEntities(entityStatus),
        editableFlg: EDITABLE_FLG.ON,
        deletableFlg: DELETE_ABLE_FLG.OK,
        visibleFlg: VISIBLE_FLG.ON,
        unAddableArea: tmpFlow.unAddableArea || [],
        tradingChannelInfo: {
          flowType: tmpFlow.tmpFlowType,
          channelFlowNo: this.tmpChannelFlowNo,
          deleteChannel: undefined, // Ph6時点要件なし。現時点未使用
        }
      };
    },
    // 新しいフローの配列を返却します
    createNewFlows() {
      this.tmpChannelFlowNo++;
      return _.map(this.tmpTradingFlowList, tmpFlow => {
        return this.createNewFlow(tmpFlow);
      });
    },
    // プロセスを追加します
    addProcess({ entityTypeCd, processId, processType, flowUuid, tradingFlowId }) {
      const flowIndex = _.findIndex(this.form.items, item => {
        return (item.tradingFlowId && item.tradingFlowId === tradingFlowId) || (item.flowUuid && item.flowUuid === flowUuid);
      });
      const flow = this.form.items[flowIndex];
      const newProcess = {
        processType: processType,
        processId: processId,
        processName: _.get(_.find(processNames, {processType: processType}), 'nameEn'),
        processUserMemo: '',
        fromCompanyRoleCd: '',
        fromCustomerId: '',
        fromCompanyId: '',
        fromSectionId: '',
        fromSectionName: '',
        toCompanyRoleCd: '',
        toCustomerId: '',
        toCompanyId: '',
        toSectionId: '',
        toSectionName: '',
        uuid: generateUuid(),
        editableFlg: EDITABLE_FLG.ON,
        deletableFlg: DELETE_ABLE_FLG.OK,
      };

      const entityStatus = _.find(flow.entityStatus, { entityTypeCd: entityTypeCd });
      if (entityStatus) {
        entityStatus.processStatus.push(newProcess);
      } else {
        flow.entityStatus.push({
          entityTypeCd: entityTypeCd,
          processStatus: [newProcess],
        })
      }
      const ordered = orderProcesses(orderEntities(flow.entityStatus));
      flow.entityStatus.splice(0, flow.entityStatus.length);
      flow.entityStatus.push(...ordered);
      this.onResize();
    },
    // プロセスを更新します
    setProcess(o, p) {
      const flow = _.find(this.form.items, item => {
        return (item.tradingFlowId && item.tradingFlowId === p.tradingFlowId) || (item.flowUuid && item.flowUuid === p.flowUuid);
      });
      const process = _.find(_.find(flow.entityStatus, { entityTypeCd: p.entityTypeCd }).processStatus, o => {
        return o.processId === p.processId && (o.processSeq && p.processSeq ? o.processSeq === p.processSeq : o.uuid === p.uuid);
      });
      if (o.sectionId.startsWith(SECTION_PREFIX.SECTION_PREFEX)) {
        process[`${p.fromTo}CustomerId`] = o.tradingId;
        process[`${p.fromTo}CompanyId`] = null;
      } else {
        process[`${p.fromTo}CompanyId`] = o.tradingId;
        process[`${p.fromTo}CustomerId`] = null;
      }
      process[`${p.fromTo}CompanyShortName`] = o.tradingShortName;
      process[`${p.fromTo}CompanyName`] = o.tradingName;
      process[`${p.fromTo}SectionId`] = o.sectionId;
      process[`${p.fromTo}SectionName`] = o.sectionName;
      process[`${p.fromTo}SectionShortName`] = o.sectionShortName;
    },
    // PB-271 プロセスメモ登録
    setProcessUserMemo(p) {
      const flow = _.find(this.form.items, item => {
        return (item.tradingFlowId && item.tradingFlowId === p.tradingFlowId) || (item.flowUuid && item.flowUuid === p.flowUuid);
      });
      const process = _.find(_.find(flow.entityStatus, { entityTypeCd: p.entityTypeCd }).processStatus, o => {
        return o.processId === p.processId && (o.processSeq ? o.processSeq === p.processSeq : o.uuid === p.uuid);
      });
      process.processUserMemo = p.processUserMemo;
      this.isDirty = true;
    },
    // プロセスを削除します
    removeProcess(p) {
      const flow = _.find(this.form.items, item => {
        return (item.tradingFlowId && item.tradingFlowId === p.tradingFlowId) || (item.flowUuid && item.flowUuid === p.flowUuid);
      });
      const entity = _.find(flow.entityStatus, { entityTypeCd: p.entityTypeCd });
      const flowType = _.get(flow, 'tradingChannelInfo.flowType');

      // 三国間取引のフローかつ、フロー内のエンティティが1つで、かつそのエンティティのプロセスが1つの場合、フローを削除するかどうかを確認します
      if (flowType && flow.entityStatus.length === 1 && entity.processStatus.length === 1) {
        this.removeFlow(flow);
      } else {
        const index = _.findIndex(entity.processStatus, o => {
          return o.processId === p.processId && (o.processSeq ? o.processSeq === p.processSeq : o.uuid === p.uuid);
        });
        if (p.processSeq) {
          if (!flow.deleteProcessIds) {
            flow.deleteProcessIds = [];
          }
          flow.deleteProcessIds.push({
            entityId: p.entityId,
            deleteProcessId: p.processId,
            deleteProcessSeq: p.processSeq
          });
        }
        entity.processStatus.splice(index, 1);
        if (_.isEmpty(entity.processStatus)) {
          flow.entityStatus.splice(_.findIndex(flow.entityStatus, { entityTypeCd: p.entityTypeCd }), 1);
        }
        if (_.isEmpty(flow.entityStatus)) {
          const tradingFlowId = flow.tradingFlowId;
          this.form.items.splice(_.findIndex(this.form.items, flow), 1);
          if (tradingFlowId) {
            this.deleteTradingFlowIds.push(tradingFlowId);
          }
        }
        this.onResize();
      }
    },
    // フローを追加します
    addFlow() {
      const newFlows = this.createNewFlows();
      this.form.items = this.form.items.concat(newFlows);
      this.expandRowKeys = this.expandRowKeys.concat(newFlows.map(flow => flow.flowUuid));
    },
    // フローを削除します
    removeFlow(row) {
      let message = 'Are you sure you want to delete this data?';
      const flowType = _.get(row, 'tradingChannelInfo.flowType');
      if (flowType) {
        message = 'Offshore trading is selected. Are you sure you want to delete the set of paired export and import flows?';
      }
      this.$store
        .dispatch('SHOW_CONFIRM', message)
        .then(() => {
          const flows = this.getPairFlows(row);
          flows.forEach(flow => {
            const index = _.findIndex(this.form.items, item => {
              return (item.tradingFlowId && item.tradingFlowId === flow.tradingFlowId) || (item.flowUuid && item.flowUuid === flow.flowUuid);
            });
            this.form.items.splice(index, 1);
            if (flow.tradingFlowId) {
              this.deleteTradingFlowIds.push(flow.tradingFlowId);
            }
          });
          this.resetCurrentFlow();
        })
        .catch(() => {});
    },
    // 下書き削除します
    deleteDraft() {
      const params = {
        // bff_td_9 取引管理削除 BFF
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'post_/v1/tradings/{companyId}/manages/delete/{tradingId}',
          path: {
            companyId: this.companyId,
            tradingId: this.tradingId,
          },
        }
      };

      this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete this data?')
        .then(() => {
          this.$store.commit('START_PROCESS');
          $api
          .request(params)
          // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.$store.commit('END_PROCESS');
            setTimeout(() => {
              this.$store.dispatch('SHOW_COMPLETED');
            }, 500);
            this.$router.push({name: 'ListTrading'});
          })
          .catch(err => {
            this.$store.commit('END_PROCESS');
            this.$store.dispatch('SHOW_ALERT', err.message);
          });
        })
        .catch(() => {});
    },
    // 新規登録をキャンセルします
    cancel() {
      this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure to go back? (Unsaved data shall be deleted)')
        .then(() => {
          if (window.history.length > 2) {
            this.$router.go(-1);
          } else {
            this.$router.push('/');
          }
        })
        .catch(() => {});
    },
    // 指定されていないセクションがあるかを返却します
    hasEmptySections() {
      return !!this.$el.querySelector('.invalid');
    },
    // 削除したプロセスがあればコンファームを出します
    async confirmDeleteProcess() {
      const count = _.reduce(this.form.items, (sum, flow) => {
        sum += _.size(flow.deleteProcessIds);
        return sum;
      }, 0);
      if (count === 0) {
        return true;
      } else {
        return await this.$store
          .dispatch('SHOW_CONFIRM', 'Are you sure you want to preceed? Deleted data will be permanently deleted.')
          .then(() => true)
          .catch(() => false);
      }
    },
    // 下書き保存します
    async save() {
      if (await this.confirmDeleteProcess() === false) {
        return;
      }
      // 下書きの時は必須チェックをしない
      this.$store.commit('START_PROCESS');
      this.shouldValidation = false;
      if (await this.validationCheck('form')) {
        const tradingFlowList = _.map(this.form.items, flow => {
          const entityStatus = _.map(flow.entityStatus, s => {
            return {
              ...s,
              provisionalId: s.entityId ? undefined : generateUuid(20),
            };
          });
          return {
            ...flow,
            entityStatus: entityStatus,
          }
        });
        const data = {
          tradingInfo: {
            tradingId: this.tradingId,
            tradingCreateUserId: this.tradingInfo.tradingCreateUserId,
            tradingCreateUserName: this.tradingInfo.tradingCreateUserName,
            tradingCreateUserIcon: this.tradingInfo.tradingCreateUserIcon,
            tradingCreateDate: this.tradingInfo.tradingCreateDate,
            tradingUpdateUserId: this.tradingInfo.tradingUpdateUserId,
            tradingUpdateDate: this.tradingInfo.tradingUpdateDate,
            deleteTradingFlowIds: this.deleteTradingFlowIds,
            tradingName: this.form.newTradingName,
            tradingPatternInfo: this.tradingInfo.tradingPatternInfo,
            tradingFlowList: tradingFlowList
          }
        };

        // bff_td_7 取引管理新規登録時下書き保存BFF
        const params = {
          lslConfig: {
            serviceCode: 'tw-transaction-bff-api',
            apiCode: 'post_/v1/tradings/{companyId}/manages/wk',
            path: {
              companyId: this.companyId
            }
          },
          data: data
        };

        this.$store.commit('START_PROCESS');
        $api
          .request(params)
          .then(res => {
            this.$store.commit('END_PROCESS');
            // this.$router.push({name: 'ListTrading'});
            setTimeout(() => {
              this.$store.dispatch('SHOW_COMPLETED');
            }, 500);

            this.deleteTradingFlowIds = [];
            this.tradingInfo = _.merge(this.tradingInfo, res.tradingInfo);
            const tmp = this.flatten(res.tradingInfo.tradingFlowList, 'linkageInfo');
            this.form.items = _.map(this.form.items, (item, i) => {
              return {
                ..._.merge(item, tmp[i]),
                deleteProcessIds: [],
              };
            });

            this.resetCurrentFlow();

            if (this.$route.name !== 'SavedTrading') {
              this.$router.replace({
                name: 'SavedTrading',
                params: { tradingId: res.tradingInfo.tradingId },
              });
            }
          })
          .catch(err => {
            this.$store.commit('END_PROCESS');
            this.$store.dispatch('SHOW_ALERT', err.message);
          });
      } else {
        this.$store.commit('END_PROCESS');
        this.$store.dispatch('SHOW_ALERT', 'Please check the input and try again.');
      }
    },
    // 取引を登録します
    async register() {
      this.shouldValidation = true;
      if (this.hasEmptySections()) {
        this.$store.dispatch('SHOW_ALERT', 'Please check the input and try again.');
        return;
      }
      if (await this.confirmDeleteProcess() === false) {
        return;
      }
      this.$store.commit('START_PROCESS');
      if (await this.validationCheck('form')) {
        const tradingFlowList = _.map(this.form.items, flow => {
          const entityStatus = _.map(flow.entityStatus, s => {
            return {
              ...s,
              provisionalId: s.entityId ? undefined : generateUuid(20),
            };
          });
          return {
            ...flow,
            entityStatus: entityStatus,
          }
        });
        const data = {
          tradingInfo: {
            tradingId: this.tradingId,
            tradingCreateUserId: this.tradingInfo.tradingCreateUserId,
            tradingCreateUserName: this.tradingInfo.tradingCreateUserName,
            tradingCreateUserIcon: this.tradingInfo.tradingCreateUserIcon,
            tradingCreateDate: this.tradingInfo.tradingCreateDate,
            tradingUpdateUserId: this.tradingInfo.tradingUpdateUserId,
            tradingUpdateDate: this.tradingInfo.tradingUpdateDate,
            deleteTradingFlowIds: this.deleteTradingFlowIds,
            tradingName: this.form.newTradingName,
            tradingPatternInfo: this.tradingInfo.tradingPatternInfo,
            tradingFlowList: tradingFlowList
          }
        };

        // bff_td_10 取引管理新規登録BFF
        const params = {
          lslConfig: {
            serviceCode: 'tw-transaction-bff-api',
            apiCode: 'post_/v1/tradings/{companyId}/manages',
            path: {
              companyId: this.companyId
            }
          },
          data: data
        };

        $api
          .request(params)
          .then(res => {
            this.$store.commit('END_PROCESS');
            this.resetCurrentFlow();
            setTimeout(() => {
              this.$store.dispatch('SHOW_COMPLETED');
            }, 500);
            // this.$router.push({name: 'ListTrading'});
            this.$router.replace({
              name: 'ManageTrading',
              params: { tradingId: res.tradingInfo.tradingId },
            });
          })
          .catch(err => {
            this.$store.commit('END_PROCESS');
            this.$store.dispatch('SHOW_ALERT', err.message);
          });
      } else {
        this.$store.commit('END_PROCESS');
        this.$store.dispatch('SHOW_ALERT', 'Please check the input and try again.');
      }
    },
    onResize() {
      const maxHeight = this.$refs.container.clientHeight - this.$refs.pos.offsetTop - 24;
      if (this.maxHeight === maxHeight) {
        this.maxHeight = this.maxHeight - 1;
        this.$nextTick(() => {
          this.maxHeight = maxHeight;
        });
      } else {
        this.maxHeight = maxHeight;
      }
    },
    handleCurrentChange(val) {
      this.currentFlow = val;
    },
    // フローの選択を解除します
    resetCurrentFlow() {
      this.currentFlow = null;
      this.$refs.table.setCurrentRow();
    },
    // テーブルのrow-keyを返却します（アコーディオン開閉状態保持）
    getRowKey(row) {
      return row.flowUuid || row.tradingFlowId;
    },
    // Menuボタン切替
    onDropDownClick(command) {
      if (command === 'viewPattern') {
        this.toggleViewPattern();
      } else if (command === 'setSection') {
        this.toggleSetSection();
      }
    },
    // View Pattern
    toggleViewPattern() {
      this.isShowViewPattern = !this.isShowViewPattern;
    },
    // Set Section
    toggleSetSection() {
      this.isShowSetSection = !this.isShowSetSection;
    },
    assignSection(exporter, importer, forwarder, overWrite = false) {
      if (exporter) this.setSection(exporter, COMPANY_ROLE_CD.EXPORTER, overWrite);
      if (importer) this.setSection(importer, COMPANY_ROLE_CD.IMPORTER, overWrite);
      if (forwarder) this.setSection(forwarder, COMPANY_ROLE_CD.EXPORT_FREIGHT_FORWARDER, overWrite);
      this.toggleSetSection();
    },
    setSection(section, role, overWrite) {
      const targetFlow = _.find(this.form.items, item => {
        return (item.tradingFlowId && item.tradingFlowId === this.currentFlow.tradingFlowId) || (item.flowUuid && item.flowUuid === this.currentFlow.flowUuid);
      });
      let entityStatus = targetFlow.entityStatus;
      if (entityStatus) {
        const tmpProcessList = _.get(this.getTmpFlow(targetFlow), 'tmpProcessList', []);
        entityStatus = _.map(entityStatus, entity => {
          entity.processStatus = _.map(entity.processStatus, process => {
            const tmpProcess = _.find(tmpProcessList, ['tmpProcessId', process.processId]);
            if (tmpProcess && process.editableFlg === EDITABLE_FLG.ON) {
              if (Number(tmpProcess.tmpFromRoleId) === role) {
                if (overWrite || !process.fromSectionId) {
                  process = this.setProcessSection(process, section, 'from');
                }
              }
              if (Number(tmpProcess.tmpToRoleId) === role) {
                if (overWrite || !process.toSectionId) {
                  process = this.setProcessSection(process, section, 'to');
                }
              }
            }
            return process;
          })
          return entity;
        })
      }
    },
    setProcessSection(process, section, fromTo) {
      return {
        ...process,
        [`${fromTo}CustomerId`]: section.sectionId.startsWith(SECTION_PREFIX.SECTION_PREFEX) ? section.tradingId : null,
        [`${fromTo}CompanyId`]: section.sectionId.startsWith(SECTION_PREFIX.SECTION_PREFEX) ? null : section.tradingId,
        [`${fromTo}CompanyShortName`]: section.tradingShortName,
        [`${fromTo}CompanyName`]: section.tradingName,
        [`${fromTo}SectionId`]: section.sectionId,
        [`${fromTo}SectionName`]: section.sectionName,
        [`${fromTo}SectionShortName`]: section.sectionShortName,
        [`${fromTo}CompanyIcon`]: section.tradingIcon,
      }
    },
    getTmpFlow(flow) {
      const flowType = _.get(flow, 'tradingChannelInfo.flowType');
      if (flowType) {
        return _.find(this.tmpTradingFlowList, {tmpFlowType: flowType}) || {};
      } else {
        return this.tmpTradingFlowList[0];
      }
    },
    // 取り引きチャネルが同じフローを輸出、輸入の順で返却します
    getPairFlows(flow) {
      let flows = [flow];
      const flowType = _.get(flow, 'tradingChannelInfo.flowType');
      if (!flowType) {
        // 三国間取引じゃないときはそのまま返却
        return flows;
      }

      const tradingChannelNo = _.get(flow, 'tradingChannelInfo.tradingChannelNo');
      const channelFlowNo = _.get(flow, 'tradingChannelInfo.channelFlowNo');

      if (tradingChannelNo) {
        flows = _.filter(this.form.items, item => {
          return _.get(item, 'tradingChannelInfo.tradingChannelNo') === tradingChannelNo;
        });
      } else if (channelFlowNo) {
        flows = _.filter(this.form.items, item => {
          return _.get(item, 'tradingChannelInfo.channelFlowNo') === channelFlowNo;
        });
      }

      flows = _.sortBy(flows, o => {
        return _.get(o, 'tradingChannelInfo.flowType');
      });

      return flows;
    },
    setExpandRowKeys(){
      this.expandRowKeys = this.form.items.map(item => this.getRowKey(item));
    }
  }
};
</script>

<style lang="scss" scoped>
.tw_container {
  padding-top: 43px;
  padding-bottom: 24px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.sub_buttons {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: right;
}
</style>
